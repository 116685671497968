.header-container {
  margin: 2rem;
  margin-left: 5rem;
  margin-right: 5rem;
  margin-top: 5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.header-list {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 70vh;
}

.email-icon {
  font-size: 30px;
  position: absolute;
  z-index: 50;
  color: gray;
  right: 10px;
}

.write-form form {
  display: flex;
  align-items: center;
  /* justify-content: space-evenly; */
  flex-wrap: wrap;
}
.submit {
  background-color: #23a6f0;
  border: none;
  cursor: pointer;

  padding: 1rem;
  border-radius: 10px;
  color: white;
  transition: all 1s ease-in-out;
  font-family: "Montserrat", sans-serif;
  width: 150px;
  font-weight: 600;
}
.loading {
  width: 50%;
  margin: 0 auto;
}

.select-list {
  padding: 0.9rem;
  width: 250px;
  background-color: white;

  border-radius: 10px;
}

.header-btn {
  background-color: white;
  padding: 0.5em;
  text-align: center;
  border-radius: 50px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
button i {
  font-size: 18px;
  font-weight: 800;
}

button div {
  height: 25px;
  width: 25px;
  margin-right: 0.5rem;
  border-radius: 50%;
}

.logo-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.logo-icon {
  font-size: 25px;
  color: #062451;
}

.header-main {
  text-align: center;
  width: 80%;
}

.header-main-p1 {
  font-size: 1.5rem;
  color: #23a6f0;
  margin-bottom: 2rem;
  font-family: "Montserrat", sans-serif;
}
.header-main-text {
  color: white;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.2rem;
  font-family: "Montserrat", sans-serif;
}

.header-cards {
  width: 80%;
  margin: 0 auto;

  margin-top: 5rem;
}

.header-cards h4 {
  color: #23a6f0;
  font-size: 18px;
}

.header-cards h1 {
  color: white;
  margin-top: 1.5rem;
  font-family: "Montserrat Bold", sans-serif;
}

.header-cards p {
  margin-top: 1rem;
  color: white;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
}

.header-main h2 {
  font-size: 3.2rem;
  margin-top: 1.5rem;
  font-family: "Montserrat", sans-serif;
}

.header-start {
  background-color: #23a6f0;
  color: white;
  padding: 0.8rem;
  border-radius: 5px;
  border: none;
  margin-right: 1rem;
  width: 30%;
  cursor: pointer;
}

.header-signup {
  cursor: pointer;
  background-color: #062451;
  color: #23a6f0;
  border-color: #23a6f0;
  padding: 0.8rem;
  border-radius: 5px;
  width: 20%;
  border: 1px solid #23a6f0;
}
.react {
  transform: translate(150px, 15px);
}
.bns {
  transform: translate(-150px, 15px);
}

.pm {
  transform: translate(130px, 15px);
}
.ds {
  transform: translate(-100px, 15px);
}

.ai {
  transform: translate(90px, 15px);
}
.dsn {
  transform: translate(-150px, 15px);
}
.aws {
  transform: translate(150px, 15px);
}

.as {
  transform: translate(-120px, 15px);
}

.react,
.pm,
.ai,
.aws {
  animation: flow1 5s alternate 1 none;
}

.bns,
.as,
.ds,
.dsn {
  animation: flow2 5s alternate 1 none;
}

@keyframes flow1 {
  from {
    transform: translate(-80px, 0px);
  }
}

@keyframes flow2 {
  from {
    transform: translate(80px, 0px);
  }
}

.header-btn1 {
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: white;
  padding: .5em;
  text-align: center;
  border-radius: 50px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease-out;
  float: left;
  cursor: pointer;
  border: none;


}

.header-btn1 img {
  width: 25px;
}

.header-card-container {
  margin: 2rem;

  margin-top: 5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 2rem;
}

.header-card-icon {
  background-color: #23a6f0;
  width: 60px;
  border-radius: 15px;
  font-size: 30px;
  padding: 1rem;
  color: white;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.white {
  color: white;
  margin-left: 5rem;
}
.header-card-line {
  width: 30%;

  margin-top: 1rem;
  border-top: 2.8px solid #e74040;
  margin-bottom: 2rem;
}

.header-card {
  background-color: white;
  color: black;

  margin: 1rem;
  padding: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
  width: 330px;
  height: 350px;
}

.header-card h3 {
  margin-top: 2rem;
}
.header-card-txt {
  margin-bottom: 1rem;
  line-height: 1.2rem;
  letter-spacing: 0.5px;
  font-family: "Montserrat", sans-serif;
}

@media screen and (max-width: 640px) {
  .header-container {
    margin: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 5rem;
  }
  .header-list {
    display: none;
  }
  .header-main h2 {
    font-size: 2.5rem;
    margin-top: 1rem;
  }
  .header-start {
    padding: 0.6rem;
    width: 40%;
  }
  .header-signup {
    width: 40%;
    padding: 0.6rem;
  }
  .header-card-container {
    margin: 0.2rem;
    margin-top: 5rem;
  }
  .header-card {
    flex-grow: 1;
  }
}

@media screen and (max-width: 768px) {
  .header-container {
    margin: 1rem;

    margin-top: 5rem;
  }
  .react {
    transform: translate(40px, 15px);
  }
  .bns {
    transform: translate(-40px, 15px);
  }

  .pm {
    transform: translate(5px, 15px);
  }
  .ds {
    transform: translate(-0px, 15px);
  }

  .ai {
    transform: translate(0px, 15px);
  }
  .dsn {
    transform: translate(-15px, 15px);
  }
  .aws {
    transform: translate(40px, 15px);
  }

  .as {
    transform: translate(-0px, 15px);
  }

  .header-main h2 {
    font-size: 2.5rem;
    margin-top: 1rem;
  }
  .header-start {
    padding: 0.6rem;
    width: 40%;
  }
  .header-signup {
    width: 40%;
    padding: 0.6rem;
  }
  .write-form {
    padding: 1rem;
  }

  .write-form form {
    display: flex;
  }
  .submit {
    display: block;
  }

  .header-card {
    flex-grow: 1;
  }
}
@media screen and (max-width: 950px) {
  .header-card-container {
  }

  .header-card {
    flex-basis: 45%;
  }
}

@media screen and (max-width: 1280px) {
  .header-card {
    width: 300px;
    height: 350px;
  }
}

@media screen and (max-width: 1280px) {
  .header-card {
    width: 300px;
    height: 350px;
  }
}

@media screen and (max-width: 1024px) {
  .react {
    transform: translate(80px, 15px);
  }
  .bns {
    transform: translate(-60px, 15px);
  }

  .pm {
    transform: translate(50px, 15px);
  }
  .as {
    transform: translate(-45px, 15px);
  }
  .ds {
    transform: translate(-20px, 15px);
  }

  .ai {
    transform: translate(25px, 15px);
  }
  .dsn {
    transform: translate(-55px, 15px);
  }
  .aws {
    transform: translate(110px, 15px);
  }

  .header-main h2 {
    font-size: 2.5rem;
    margin-top: 1rem;
  }
  .header-start {
    padding: 1rem;
    width: 40%;
  }
  .header-signup {
    width: 40%;
    padding: 1rem;
  }
  .header-card-container {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1200px) {
  .header-card-container {
    justify-content: flex-start;
  }
  .header-card {
    height: 400px;
  }
}

@media screen and (max-width: 1280px) {
  .react {
    transform: translate(80px, 15px);
  }
  .bns {
    transform: translate(-80px, 15px);
  }

  .pm {
    transform: translate(30px, 15px);
  }
  .as {
    transform: translate(-25px, 15px);
  }
  .ds {
    transform: translate(-10px, 15px);
  }

  .ai {
    transform: translate(35px, 15px);
  }
  .dsn {
    transform: translate(-40px, 15px);
  }
  .aws {
    transform: translate(80px, 15px);
  }
  .header-start {
    padding: 1rem;
    width: 45%;
  }
  .header-signup {
    width: 45%;
    padding: 1rem;
  }

  .header-card-container {
    justify-content: flex-start;
  }
  .header-card {
    width: 350px;
  }
}
@media screen and (max-width: 720px) {
  .header-cards {
    width: 90%;
  }
}
