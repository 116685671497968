.courseslist-container {
  background-color: white;
 height: 100%;
  padding: 3rem;

}
.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.searchbar {
  width: 70%;
  border-radius: 5px;
  position: relative;
}

.searchbar input {
  border-radius: 50px;
  width: 100%;
  padding: 1rem;
  border: 0.5px solid rgb(141, 140, 140);
}

.searchbar input:focus {
  outline: none !important;
  border: 2px solid #23a6f0;
}

.search-icon {
  position: absolute;
  right: 5px;
  top: 3px;
  font-size: 20px;
  background-color: #23a6f0;
  padding: 0.75rem;
  border-radius: 25px;
  color: white;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.skill {
  background: #ffffff;
  border: 1px solid #d6d6d6;
  border-radius: 2px;
}

.skill input {
  border: none;
  width: 100%;
  min-width: 50px;
}
.skill input:focus {
  outline: none;
}
ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
}
ul li {
  align-items: center;
  background: #778ca3;
  border-radius: 2px;
  color: #ffffff;
  list-style: none;
  margin: 5px;
  padding: 5px;
}
ul li button {
  align-items: center;
  background: #333333;
  border: none;
  color: #ffffff;
  cursor: pointer;
  height: 15px;
  width: 15px;
  font-size: 12px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  margin-left: 8px;
  transform: rotate(45deg);
}
ul .input-skill {
  background: none;
  flex-grow: 1;
}

.pathways-list{

  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
flex-wrap: wrap;
gap: 50px;



}

.bloc-tabs {

  width: 85%;
  margin: 0 auto;
  display: flex;
  margin-top: 5rem;
  border-bottom: 1px solid black;
}
.tabs {

  padding: 15px;
  text-align: center;
  width: 50%;
  background: rgba(128, 128, 128, 0.075);
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  position: relative;
  outline: none;
}
/* .tabs:not(:last-child){
  border-right: 1px solid rgba(0, 0, 0, 0.274);
} */

.active-tabs  {
  background: white;
  border-bottom: 1px solid transparent;
  font-weight: 700;
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 3px;
  border-radius: 10px;
  background: #252B42;

}

.bloc-tabs button{
  border: none;
  font-size: 18px;


}


.courselist-fields{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;

}
.select-categories select{

  padding: 1rem;
  border-color:#23a6f0 ;
  font-weight: 600;
margin-top: 2rem;
}

.course-card{
  position: relative;

  width: 350px;
  background-color: #252B42;
  color: white;
height: 600px;
}
.course-img{
  width: 350px;
  height: 250px;
  object-fit: cover;
}

.course-details{
  padding: 1rem;
}
.course-details h2{
  font-size: 18px;
  font-weight: 600;
  height: 45px;

}
.curator-img{
width: 50px;
border-radius: 50%;
height: 50px;
margin-right: 1rem;
}
.course-created-by{
  display: flex;
  margin-top: 1rem;
}
.curator-cmpy{
  width: 25px;
  height: 25px;
  margin-top: .5rem;
}
/* .curator-details{
  display: flex;
  background-color: red;
  flex-direction: row;
width: 100%;
align-items: center;
justify-content: left;
} */
.course-details p{
  font-size: 14px;
}
.curator-data{
  display: flex;
  flex-direction: column;
  
}
.course-curator-name,.course-curator-desg{
  font-size: 12px;
}
.about-course{
  font-size: 14px;
  margin-top: 1rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.course-footer{
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: .5rem;
  position: absolute;
  bottom: 10px;
  gap: 100px;
  
}
.course-footer h3{
  font-size: 16px;

}
.gif-container{
  display: flex;
align-items: center;
justify-content: center;

flex-direction: column;
}
.loading-gif{
width: 400px;
}

.course-footer button{
  padding: .8rem;
  border-radius: 10px;
  border: none;
  background-color: #FFFCFC5E;
  color: white;
  cursor: pointer;
}
.foot-bot{
  bottom: 15%;
  position: absolute;

}

.tab-heading{


  width: 90%;
  margin: 0 auto;
  margin-top: 1.5rem;
background-color:#FFFAD0;
padding: 1.5rem;
border-radius: 10px;
}
.tab-heading p{
  margin-top: 0.5rem;
}

.foot-bot-bnt{
  bottom: 3%;
  position: absolute;
}

.c-list-heading{

  width: 90%;
  margin: 0 auto;
}
.main-footer{
  width: 90%;
}

.pathway-card1{
  display: flex;
  flex-direction: column;
color: white;
position: relative;

  background-color: #252B42;
  border-radius: 10px;
  -webkit-box-shadow: -1px 2px 5px 4px rgba(0,0,0,0.18);
  -moz-box-shadow: -1px 2px 5px 4px rgba(0,0,0,0.18);
  box-shadow: -1px 1px 3px 4px rgb(0 0 0 / 10%);

  height: 600px;
  margin-top: 1.5rem;


}
.pathway-card1 .pathway-card-right{
  width: 350px;
}
.pathway-card1 img{
  width: 350px;
  height: 250px;
}
@media screen and (max-width:900px) {

  .c-list-heading h1{
    font-size: 26px;
  }
  .c-list-heading h2{
    font-size: 16px;
    font-weight: 600;
  }
  .searchbar{
    width: 80%;
  }
 
.courseslist-container{
  padding-left: 1rem;
  padding-right: 1rem;
}



.bloc-tabs button{
font-size: 14px;
}
.course-details p{
  font-size: 14px;
}

.course-curator-name,.course-curator-desg{
  font-size: 12px;
}
  
}

.search-icon-container:hover{
  cursor: pointer;
}

@media screen and (max-width:700px) {

  .c-list-heading h1{
    font-size: 22px;
  }
  .c-list-heading h2{
    font-size: 16px;
    font-weight: 600;
  }
  .searchbar{
    width: 70%;
  }
  .search-icon-container{
    
  }
 
.courseslist-container{
  padding-left: 1rem;
  padding-right: 1rem;
}



.bloc-tabs button{
font-size: 14px;
}
.course-details p{
  font-size: 14px;
}

.course-curator-name,.course-curator-desg{
  font-size: 12px;
}
  
}

@media screen and (max-width:580px) {

  .c-list-heading h1{
    font-size: 18px;
  }
  .c-list-heading h2{
    font-size: 14px;
    font-weight: 600;
  }
  .searchbar{
    width: 70%;
  }
  .search-icon-container{
    
  }
  ::-webkit-input-placeholder {
    font-weight: 500;

     font-size: 14px;
}
.courseslist-container{
  padding-left: 1rem;
  padding-right: 1rem;
}
.search-icon {
  position: absolute;
  right: 5px;
  top: 8px;
  font-size: 20px;
  background-color: #23a6f0;
  padding: 0.5rem;
  border-radius: 25px;
  color: white;
  padding-left: .5rem;
  padding-right: .5rem;

}

.select-categories select{
margin-left: .5rem;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-color:#23a6f0 ;
  font-weight: 700;
  font-size: 9px;
margin-top: 2rem;
}
.bloc-tabs button{
  font-size: 14px;
  }
.bloc-tabs {
width: 95%;
}
.course-details p{
  font-size: 13px;
}

.course-curator-name,.course-curator-desg{
  font-size: 11px;
}
  
}

@media screen and (max-width:500px) {
  ::-webkit-input-placeholder {

    font-size: 10px;
    font-weight: 500;
}

.bloc-tabs button{
  font-size: 11px;
  }
.bloc-tabs {
width: 95%;
}


}