.nav-container {
  margin: 2rem;
  margin-top: 0;
  padding-top: 2rem;
  margin-left: 5rem;
  margin-right: 5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.nav-logo {
  font-size: 2.5rem;
  font-weight: 700;
  flex-basis: 50%;
}

.nav-logo img {
  width: 160px;
}

.nav-close {
  font-size: 30px;
  display: none;
  position: fixed;
  padding: 0.1rem;

  background-color: white;
  color: #062451;
}

.nav-links {
  flex-basis: 40%;
}

.nav-links ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}

.nav-right {
  display: flex;
  align-items: center;

  justify-content: space-evenly;
}

.nav-login {
  font-weight: 1rem;
  font-weight: 600;
  margin-right: 0.5rem;
}

.nav-join-us {
  padding: 1rem;
  background-color: #23a6f0;
  color: white;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
}

.nav-join-us i {
  font-size: 1rem;
  margin-left: 0.5rem;
}
.menu-icon {
  color: white;
  font-size: 30px;
  display: none;
}

@media screen and (max-width: 1190px) {
  .nav-container {
    margin: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
      margin-top: 0;
    
  }
}
@media screen and (max-width: 920px) {
  .nav-container {
    margin: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
  margin-top: 0;
  }
  .nav-logo {
    font-size: 1.5rem;
  }
  .nav-right {
    flex-basis: 25%;
  }
  .nav-login {
    margin-right: 0;
  }
}

@media screen and (max-width: 870px) {
  .nav-container {

    margin: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 0;
  }
  .nav-logo {
    font-size: 1.8rem;
  }
  .nav-links ul {
    font-size: 0.8rem;
    font-weight: 600;
  }
}

@media screen and (max-width: 790px) {
  .nav-container {
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }
  .nav-logo {
    font-size: 1.7rem;
    flex-basis: 30%;
  }
  .nav-close {
    z-index: 800;
    right: 0;
    top: 0;
    transition: all 1s ease;
  }

  .show-close {
    display: block;
    transition-delay: 1s;
    transition-property: display, background-color;
  }

  .nav-links ul {
    transition: all 0.5s ease-in-out;
    font-size: 1rem;
    font-weight: 600;
    display: block;
    background-color: #062451;
    z-index: 500;
    position: fixed;
    right: -800px;
    top: 0;
    /* width: 100%; */
    height: 100vh;
    display: flex;
    flex-direction: column;
    border-radius: 1%;
    justify-content: space-evenly;
  }
  .nav-links {
    flex-basis: 0%;
  }
  .nav-right {
    flex-basis: 50%;
  }

  .menu-icon {
    display: contents;
  }
  .nav-menu-icon {
    margin-left: 1rem;
  }
}

@media screen and (max-width: 500px) {
  
  
  .nav-logo img {
    width: 120px;
    margin-top: 1rem;
  }

  .nav-join-us {
    padding: 0.5rem;
    font-size: 1rem;
    margin-top: 0.5rem;
  }
}
.show ul {
  right: 0;
}
