.footer-2{
    background-color:#F4F4F4;
    display: flex;
    align-items: center;
    justify-content: space-between;

padding: 0.5rem;
padding-left: 5rem;
padding-right: 5rem;
position: static;
bottom: 0px;
    
}

.footer-2 img{
    width: 150px;
    height: 50px;
}

.footer-social-icons i{
    font-size: 20px;
    color: white;
    background-color: #062451;
    padding: 0.25rem;
    margin: 0.25rem;
    border-radius: 20px;
}


@media screen and (max-width:500px) {
    .footer-2{
        padding-right: 2rem;
        padding-left: 2rem;
    }
}