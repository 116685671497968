.loading-container {
  width: 80%;
  margin: 0 auto;
  color: white;
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 5rem;
}
.loading-container h2 {
  font-size: 40px;
  font-family: "Montserrat ", sans-serif;
  margin-bottom: 2rem;
}
.loading-container p {
  margin-top: 3rem;
  font-size: 16px;
  font-family: "Montserrat Regular", sans-serif;
}
.loading-container img {
  width: 300px;
}
.count-down {
  display: flex;
  justify-content: space-evenly;
}
.mr-1 {
  margin-right: 1.5rem;
}
.loading-sub {
  display: flex;
  margin-top: 2rem;
  align-items: center;
}
.loading-sub h2 {
  font-size: 30px;
  color: #23a6f0;
  margin-right: 0.5rem;
  font-family: "Montserrat ", sans-serif;
}

.loading-sub h3 {
  font-size: 30px;
  margin-right: 2rem;
  font-family: "Montserrat", sans-serif;
}
.loading-sub button {
  background-color: #23a6f0;
  border: none;
  padding: 0.7rem;
  color: white;
  font-weight: 600;
  font-size: 15px;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Montserrat ", sans-serif;
}
.responsive-iframe{
  width:560px;
  height:315px;
}

@media screen and (max-width: 650px) {
  .loading-container h2 {
    font-size: 30px;
  }
  .loading-sub h2,
  .loading-sub h3 {
    font-size: 25px;
  }
  .loading-container img{
    width: 220px;
    margin-top: 2rem;
  }
  .responsive-iframe{
    width:360px;
    height:315px;
  }
  
}

@media screen and (max-width: 550px) {
  .loading-container h2 {
    font-size: 25px;
  }
  .loading-sub{

    flex-direction: column;
    gap: 10px;

    justify-content: center;
    align-items: stretch;
  }
  .loading-sub h2,
  .loading-sub h3 {
    font-size: 24px;
  }
  .loading-sub h3{
    margin-left: 1.5rem;
  }
}
