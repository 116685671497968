.login-container {
  background-color: white;
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
}

.login-left {
  flex-basis: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-container {
  width: 500px;

  max-height: 320px;
}

.login-right {
  flex-basis: 40%;
  display: flex;
  align-items: flex-start;
  padding-left: 8rem;
  flex-direction: column;
  justify-content: center;
}
.login-right h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.login-right h2 {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 30px;
  margin-top: 1rem;
  line-height: 35px;
}

.mt {
  margin-top: 2rem;
}
.login-lable label {
  font-size: 16px;
  font-weight: 550;
  line-height: 18px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.login-lable input {
  border: 1px solid black;
  padding: 0.8rem;
  border-radius: 5px;
  width: 150%;
  margin-top: 0.5rem;
}
.login-lable input:focus {
  outline: none !important;
  border: 1px solid rgb(49, 49, 49);
}

.login-lable input:active {
  border: 1px solid rgb(0, 0, 0);
}

.login-forgot {
  margin-top: 2rem;
  color: #2c5282;
}

.login-btn {
  width: 300px;
  background-repeat: 50px;
  background-color: #23a6f0;
  color: white;
  font-weight: 500;
  font-size: 16px;
  padding: 0.8rem;
  border: none;
  border-radius: 5px;
}

.g-icon {
  max-width: 50px;
  height: 30px;
}

.google-btn {
  background-color: #2c5282;
  display: flex;
  align-items: center;
  width: 300px;
  background-repeat: 50px;
  justify-content: space-evenly;
  color: white;
  padding: 0.5rem;
  border-radius: 5px;
  border: none;
}

.login-footer {
  margin-top: 2rem;
  font-weight: 500;
  margin-left: 1rem;
}
.login-footer span {
  color: #23a6f0;
  font-weight: 600;
  text-decoration: underline;
}
