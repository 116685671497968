
.testcardswiper{
    width: 85%;
    margin: 0 auto;
    margin-top: 5rem;
}


.swiper {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    border-radius: 5px;

  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
flex-shrink: 0;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  
  .card1{
      width: 100%;
      position: relative;
      padding: 3rem;

  }

  .quotest{
      margin-bottom:1rem;
      color: #062451;
  }
  .quotesb{
    margin-top:1rem;
    color: #062451;
}
.user-cmt1{
    width: 90%;
  margin: 0 auto;
 font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: rgb(76, 76, 76);
}
.user1{
    width: 65%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    bottom: 5%;

    
}

.user-name1{
    color: #23a6f0;
}
.user-role1{
    color: gray;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 16px;
}
.user1 img{
    width: 100px;
    height: 100px;
    object-fit: fill;
    border-radius: 50%;
    margin-right: 1.2rem;
}


@media screen and (min-width:1650px) {

    .card1{
        height: 650px;
    }
    .user1{
        width: 80%;
        margin: 0 auto;
        
    }

}



@media screen and (max-width:1650px) {

    .card1{
        height: 680px;
    }
    .user1{
        width: 80%;
        margin: 0 auto;
 
        
    }

}



@media screen and (max-width:1530px) {

    .card1{
        height: 700px;
    }
    .user1{
        width: 80%;
        margin: 0 auto;
        left: 20px; 
        
    }

}


@media screen and (max-width:1450px) {

    .card1{
        height: 800px;
    }
    .user1{
        width: 90%;
        margin: 0 auto;
        left: 10px; 
    }

}


@media screen and (max-width:1350px) {

    .card1{
        height: 530px;
    }
    .user1{
        width: 80%;
        margin: 0 auto; 
    }

}


@media screen and (max-width:1200px) {

    .card1{
        height: 600px;
    }
    .user1{
        width: 80%;
        margin: 0 auto; 
    }

}


@media screen and (max-width:1100px) {

    .card1{
        height: 650px;
    }
    .user1{
        width: 80%;
        margin: 0 auto; 
    }

}

@media screen and (max-width:1000px) {

    .card1{
        height: 690px;
    }
    
    .user1{
        width: 80%;
        margin: 0 auto; 
    }

}
@media screen and (max-width:899px) {

    .card1{
height: 420px;
    }
    .user1{
        width: 80%;
        margin: 0 auto; 
    }
  
}



@media screen and (max-width:700px) {

    .card1{
height: 480px;
    }
    .user1{
        width: 80%;
        margin: 0 auto; 
    }
  
}



@media screen and (max-width:600px) {

    .card1{
height: 500px;
    }
    .user1{
        width: 85%;
        margin: 0 auto; 
    }
    .user-name1{
        font-size: 16px;
    }
    .user-role1{
        font-size: 16px;
    }
    .user1 img {
        width: 70px;
        height: 70px;
    }

}




@media screen and (max-width:550px) {

    .card1{
height: 530px;

    }
   
    .user1{
        width: 85%;
        margin: 0 auto; 
    }
    .user-name1{
        font-size: 16px;
    }
    .user-role1{
        font-size: 16px;
    }
    .user1 img {
        width: 70px;
        height: 70px;
    }
    .user-cmt1{
        font-size: 16px;
    }

} 


@media screen and (max-width:450px) {

    .card1{
height: 690px;

    }


} 




