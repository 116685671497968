.how-container{
    color: white;
    width: 85%;
    margin: 0 auto;
    margin-top: 5rem;

}
.how-container h1{
width: 95%;
margin: 0 auto;

    font-family: "Montserrat Bold", sans-serif;
}


.how-img{
    width: 400px;
    height: auto;

}
.how-flex{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 2rem;
    flex-wrap: wrap;
    gap: 30px;
}
.how-img-container{
    flex-basis: 40%;
}
.how-data{
    flex-basis: 40%;
}
.how-data h3{
    font-size: 30px;
}
.how-data h5{
    font-size: 20px;
margin-top: 1rem;
}
.how-data p{
margin-top: 1rem;
}

@media screen and (max-width:880px) {
   
    .how-img{
        width: 300px;
    }
    .how-img-container,.how-data{
        flex-basis: 40%;
    }
    
}


@media screen and (max-width:700px) {
   .order-flip .how-img-container{
    order: 1;
   }
   .order-flip .how-data{
    order: 2;
   }
    .how-img-container{
        flex-basis: 80%;
        margin-top: 1rem;
    }
    .how-data{
        margin-top: 1rem;
        flex-basis: 90%;
    }
    .how-data h3{
        font-size: 20px;
    }
    .how-data h5{
        font-size: 18px;
    }

    .how-container h1{
        font-size: 25px;
        }
    
}