.orb {
  display: inline-block;
  margin: 0 10px;
  float: left;
  transition: all 0.2s ease-out;
}

.marquee-container{
  overflow: hidden;
}

.orb:hover {
  transform: scale(1.2);
  opacity: 1;
  cursor: pointer;
}

.marquee {
  height: 200px;
  width:1230px;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  margin: 0 auto;

}

.marquee--inner {

  display: block;
  width:270%;
  margin: 50px 0;
  position: absolute;
  animation: marquee 15s linear infinite;
  transition: all 1s ease-in-out;
}

.marquee--inner:hover {
  animation-play-state: paused;
}

span {
  float: left;
  display: flex;
  width: 50%;
}

@keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}


@media screen and (max-width:400px) {
  .marquee--inner {
  width:300%;
  }
}