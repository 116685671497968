
@import url('http://fonts.cdnfonts.com/css/montserrat');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@200&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');
title {
font-weight: 800;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
@font-face {
  font-family: "Monstserrat";
  src: url("fonts/Montserrat-Regular.ttf") format('ttf');
  src: url("fonts/Montserrat-Light.ttf") format('ttf');
  src: url("fonts/Montserrat-Bold.ttf") format('ttf');
  }


html {
  scroll-behavior: smooth;
}
body {

  overflow-x: hidden;
  font-family: "Montserrat", sans-serif;
}
.bghome{
  background: #062451;
}

.link:hover{
   cursor: pointer;
}

.scrollup {
  position: fixed;
  right: 1rem;
  bottom: -30%;
  background-color: white;
  display: inline-flex;
  padding: 0.25rem;
  z-index: var(--z-tooltip);
  opacity: 0.8;
  transition: 0.4s;
}
.scrollup:hover {
  opacity: 1;
}

.scrollup__icon {
  font-size: 1.5rem;
  color: #062451;
}

.show-scroll {
  bottom: 3rem;
}

a {
  all: unset;
}