.testmonial-container {
  width: 85%;
  margin: 0 auto;
  margin-top: 5rem;

}
.test-main h4 {
  color: #23a6f0;
  font-family: "Montserrat", sans-serif;

}

.test-main h1 {
  color: white;
  margin-top: 1.5rem;
  font-family: "Montserrat", sans-serif;
}
.test-main p {
  margin-top: 1rem;
  color: white;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;

}
.test-cards {
  margin-top: 2rem;
  display: flex;
  justify-content: space-evenly;
 
}
.card {
  background-color: white;
  flex-basis: 30%;
  padding: 3rem;
  margin: 1rem;
  position: relative;
}

.ratings {
  margin: 0 auto;
  width: 60%;
}
.user-cmt {
  width: 90%;
  margin: 0 auto;
  margin-top: 2rem;
  font-family: "Montserrat", sans-serif;
  color: rgb(76, 76, 76);
}
.user-wrapper{
  margin-top: 3rem;

}
.user {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  bottom: 0;

}

.user img {
  border-radius: 50%;
  width: 80px;
  height: 70px;
}
.user-name {

  
}

.user-role {
  color: gray;
  font-family: "Montserrat", sans-serif;
}

@media only screen and (max-width: 600px) {

  .test-cards{
    flex-wrap: wrap;
  }
.card{
  flex-basis: 90%;
}
}

@media only screen and (min-width: 600px) {


  .test-cards{
    flex-wrap: wrap;
  }
  .card{
    flex-basis:500px;
  }

}
@media only screen and (min-width: 768px) {
  .card{      
    flex-basis: 500px;
  }
}

@media only screen and (min-width: 992px) {


  .card{
      
    flex-basis: 350px;
  }
}

@media only screen and (min-width: 1200px) {
  .testmonial-container{
width: 85%;
  }

  .card{
    flex-basis: 300px;
  }
.user-name,.user-role{
  margin-left: 1rem;
}
  .user-name{
font-size: 16px;
  }
  .user-role{
    font-size: 14px;
  }
}

@media only screen and (min-width: 1350px) {
  .card{
    flex-basis: 350px;
  }

}
/* @media screen and (min-width:920px) {
  .testmonial-container{
    width: 90%;
  }
.card{
  flex-basis: 350px;
  padding: 1.5rem;
}

}
@media screen and (min-width:1200px) {
  .testmonial-container{
     width: 85%;
  }
} */
