.write-us-container {
  width: 80%;

  margin: 0 auto;
  margin-top: 5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.get-early-access {
  background-color: #23a6f0;
  border: none;
  padding: 0.7rem;
  color: white;
  font-weight: 600;
  font-size: 15px;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
}

.mt-2 {
  margin-top: 2rem;
}
.p1 {
  padding: 1rem;
}
.write-us-img {
  max-width: 300px;
}
.write-left {
  flex-basis: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.write-right {
  padding: 2rem;
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.line {
  width: 130px;

  margin-top: 1rem;
  border-top: 4.5px solid #e74040;
  margin-bottom: 2rem;
  border-radius: 25px;
}

.write-right h3 {
  margin-top: 3rem;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  line-height: 3rem;
  font-family: "Montserrat", sans-serif;
}
.write-right p {
  margin-bottom: 2rem;
  max-width: 100%;
  text-align: center;
  font-family: "Montserrat", sans-serif;
}

.added-others {
}

.input-box {
  background-color: white;
  position: relative;
  padding: 0.5rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.input-box1 {
  background-color: white;
  transition: all 1s ease-in-out;
  width: 315px;

  padding: 1rem;
  border-radius: 10px;
}
.input-box input:focus {
  outline: none;
}
.input-box1,
.select-box select {
  outline: none;
  border: none;
}
.input-box button {
  position: absolute;
  right: 0;
  background-color: #23a6f0;
  border: none;
  padding: 0.5rem;
  color: white;
  border-radius: 15px;
  margin-right: 0.5rem;
  width: 50px;
}
.input-box input {
  border: none;
  width: 300px;
  padding: 0.5rem;
}
.select-box select {
  border: none;
  width: 315px;
  padding: 1rem;
}

@media screen and (min-width: 1250px) {
  .write-form {
    width: 75%;

    margin: 0 auto;
  }
  .added-others {
    width: 85%;
  }

  .write-form form {
    justify-content: start;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 2rem;
  }
  .added-others form {
    justify-content: start;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 1rem;
  }
  .input-box input {
    width: 280px;
  }
  .input-box1,
  .select-box select {
    width: 300px;
  }
}

@media screen and (min-width: 1350px) {
  .added-others {
    width: 80%;

    margin: 0 auto;
  }
}

@media screen and (min-width: 1500px) {
  .added-others {
    width: 75%;

    margin: 0 auto;
  }
}

@media screen and (min-width: 1650px) {
  .added-others {
    width: 70%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1800px) {
  .added-others {
    width: 60%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1250px) {
  .write-form {
    width: 85%;

    margin: 0 auto;
  }
  .added-others {
    width: 58%;
    margin: 0 auto;
  }
  .write-form form {
    justify-content: start;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 2rem;
  }
  .added-others form {
    justify-content: start;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 1rem;
  }
  .input-box input {
    width: 280px;
  }
  .input-box1,
  .select-box select {
    width: 300px;
  }
}

@media screen and (max-width: 1080px) {
  .added-others {
    width: 65%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1000px) {
  .added-others {
    width: 70%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 900px) {
  .write-form {
    width: 80%;

    margin: 0 auto;
  }
  .added-others {
    width: 75%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 850px) {
  .write-form {
    width: 80%;

    margin: 0 auto;
  }
  .added-others {
    width: 80%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 800px) {
  .write-form,
  .added-others {
    width: 80%;

    margin: 0 auto;
  }
  .added-others form,
  .write-form form {
    justify-content: start;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .input-box input {
    width: 480px;
  }
  .input-box1,
  .select-box select {
    width: 500px;
  }
  .submit {
    width: 300px;
  }
}

@media screen and (max-width: 650px) {
  .write-right {
    flex-basis: 100%;
    padding: 0rem;
  }

  .write-form,
  .added-others {
    width: 90%;

    margin: 0 auto;
  }
  .added-others form,
  .write-form form {
    justify-content: start;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .input-box input {
    width: 280px;
  }
  .input-box1,
  .select-box select {
    width: 300px;
  }
}
