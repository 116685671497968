.company-container {
  width: 75%;
  margin: 0 auto;
  background-color: white;
  padding: 3rem;
  text-align: center;
  border-radius: 50px;
}

.company-heading {
  font-size: 28px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

.company-container p {
  margin-top: 2rem;
  font-family: "Montserrat", sans-serif;
  color: gray;
}
.cmpy-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cmpy-img-card {
  margin-top: 1rem;

  display: flex;
  align-items: center;

  margin-right: 1rem;
}
.cmpy-img-card img {
  width: 80px;
}
.marquee-container {
  overflow: hidden;
}

.marquee1 {
  height: 200px;
  width: 850px;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  margin: 0 auto;
}

.marquee-inner1 {
  display: block;
  width: 200%;
  margin: 50px 0;
  position: absolute;
  animation: marquee 15s linear infinite;
  transition: all 1s ease-in-out;
}

.cmpy-img {
  float: left;
  display: flex;
  width: 50%;
}

.marquee-inner1:hover {
  animation-play-state: paused;
  cursor: pointer;
}
@keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

@media screen and (max-width: 640px) {
  .company-heading {
    font-size: 1rem;
  }
  .company-container {
    width: 90%;
    padding: 2rem;
  }
}
