.pathway-container{
    background-color: white;
height: auto;
  padding: 3rem;

}

.pathway-detail{
    margin-top: 1.5rem;
    font-size: 16px;
    display: flex;
    align-items: center;
}
.pathway-detail i{
    font-size: 16px;
    margin-left: 0.3rem;
}
.p-bold{
    font-weight: 800;
}
.pathway{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 2rem;
}

.pathway-left{
    flex-basis: 65%;
 }
 .pathway-left-container{

     position: relative;
}
.profile-pic{
    width: 50px;
    height: 50px;
    border-radius:50%;
}
.curator-details{
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    background: #F5FCFF;
    border-radius: 10px;
    width: 55%;
    padding: .5rem;

}
.connect-div{
    display: flex;
    align-items: center;
    padding-right: 2rem;

}
.connect-div:hover{
    cursor: pointer;
}
.connect-img{
    width: 25px;
    height: 25px;
    border-radius: 10px;
}
.curator-details div{
    margin-top: 5px;
    display: flex;
    align-items: center;
    gap: 10px;  
}
.curator-details h6{
    font-size: 14px;
}
.pathway-line{
    display: flex;
    gap: 50px;
align-items: center;
    margin-bottom: 1rem;
    position: relative;

}
.start{
    z-index: 10;
    position: relative;
    height: 60px;
    width: 60px;
    border-radius: 20px;
color: white;

    
    background-color:#738DED;
    font-weight: 800;
z-index: 1000;
border: none;


}


.line1{

    position: absolute;
    background-color: #738DED;
width: 3px;
    left: 3.5%;
    height: 100%;
    

}



.card-btn{

    height: 65px;
    width: 65px;
    padding: 1rem;
    border-radius:50%;
    font-size: 20px;
    background-color:#738DED;
    font-weight: 800;
text-align: center;
color: white;
z-index: 1000;
border: none;

}
.pathway-right{
    flex-basis: 25%;
    background-color: aqua;
    
}

.pathway-card{
    display: flex;
    background-color: #FAFAFA;
    border-radius: 10px;
    -webkit-box-shadow: -1px 2px 5px 4px rgba(0,0,0,0.18);
    -moz-box-shadow: -1px 2px 5px 4px rgba(0,0,0,0.18);
    box-shadow: -1px 1px 3px 4px rgb(0 0 0 / 10%);

    width: 680px;
    margin-top: 1.5rem;
}
.pathway-card-left{
    position: relative;
}
.pathway-left img{
    width: 320px;
    height: 100%;
    object-fit: contain;
}

.pathway-card-type{
    position: absolute;
    top: 10px;
right:20px;
border-radius: 50px;
border: none;
background-color: #FFFAD0;
padding: 0.25rem;
font-weight: 600;
}

.pathway-card-right{
    padding: 1rem;

    width: 350px;
}
.pathway-card-right h4{
    margin-top: 1rem;
color: #23A6F0;
}
.pathway-card-right h3{
    margin-top: 1rem;
}
.pathway-card-right p{
    font-size: 14px;
    margin-top: 1rem;
}

.lessons{
    display: flex;
    align-items: center;
}
.lessons p{

  font-size: 14px;
  color: gray;
  font-weight: 500;
}
.time{

    display: flex;
    align-items: center;
}
.time i,.lessons i,.level i{
    font-size: 20px;
    margin-top: .8rem;
}
.lessons i{
color: 
#E77C40

;}
.time p,
.level p {
  margin-left: 0.5rem;
  font-size: 14px;
  color: gray;
  font-weight: 500;
}
.main-footer1{
display: flex;
align-items: center;
justify-content: center;
gap: 15px;
margin-top: 1rem;

}

.downloads{
    margin-top: 1rem;
    font-size: 14;
    color: gray;
    font-weight: 600;

}
.downloads i{
    font-weight: bold;
    margin-right: 1rem;
    font-size: 18px;
}
.downloads h3{
    color:#40BB15 ;
}

.pathway-rigth{

    flex-basis: 35%;


}
.p-right-container{
    position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}
.signup-container{
    background-color: #F3FBFF;
    padding: 1.25rem;

border-radius: 25px;    

}

.signup-container h3{
    display: flex;
    align-items: center;
    margin-bottom: 1rem;


}
.signup-img{
    margin-left: 1rem;
}
.signup-input{
    position: relative;
margin-top: 1rem;
    width: 300px;
}

.signup-input input{
    width: 300px;
border-radius: 10px;
padding: 1rem;
margin-bottom: 1rem;
border: 1px solid gray;

}
.signup-input input::placeholder{
    font-size: 16px;
    color: gray;
}
.signup-input button{
position: absolute;
right: 5px;
padding: .5rem;
top: 10px;

border: none;
border-radius: 10px;
background-color: #23A6F0;
color: white;
}

.tour-btn{
    padding: 0.25em;
margin-left: 1rem;

}
.signed-gif{
    width: 30px;
    height: 30px;
}

.signed-success{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 1rem;
}
.signup-input button:hover,.send-eamil-btn:hover{
    cursor: pointer;
}
.signup-list{
    display: flex;
    align-items: center;
margin-bottom: 1rem;
font-weight: 500;
}

.tick{
    width: 20px;
    margin-right: 1rem;


}

.explore-container{
    background-color: #FFF7E1;

    border-radius: 25px;    
    margin-top: 1rem;
    padding: 1.25rem;
}
.explore-container h3{
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
}
.explore-container img{
    margin-left: 1rem;
    width: 25px;
}
.explore-list{
    display: flex;
    align-items: center;
    font-weight: 600;
}
.explore-list img{
    margin-right: 1rem;
    margin-top: .5rem;
}
.course-link:hover{
    cursor: pointer;

}
.learn-more:hover{
    cursor: pointer;
}
.explore-container button{
    border: none;
    margin-top: 1rem;
    margin-left: 1rem;
    color: white;
    background-color: #23A6F0;
    padding: 1rem;
    border-radius: 30px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;

}

.similar-pathway-container{
    width: 90%;
    margin: 0 auto;
    margin-top: 5rem;
display: flex;
gap: 50px;
flex-wrap: wrap;

}


@media screen and (min-width:1100px) {
    .pathway-rigth{
        flex-basis: 25%;

    }
    
}

@media screen and (max-width:1100px) {

 

    .curator-details div{
        width: 100%;
           }
           .pathway-detail {
            font-size: 14px;
           }
           .curator-details{
            font-size: 16px;
           }
           .curator-bg h6{
            font-size: 14px;
           }
           .pathway-header{
            font-size: 14px;
           }

           .pathway-rigth{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-basis: 95%;
            flex-wrap: wrap;
        }

}



@media screen and (max-width:820px) {



    .pathway-left{
        width: 100%;
    }
    .pathway-card{
        flex-direction: column;
        width: 400px;
    }
   
    .pathway-left img{
        width: 350px;
        height: 100%;
        object-fit: cover;
    }
    .line1{
        left: 5.5%;
    
    }
    

    .curator-details div{
        width: 100%;
           }
           .pathway-detail {
            font-size: 14px;
           }
           .curator-details{
            font-size: 16px;
           }
           .curator-bg h6{
            font-size: 14px;
           }
           .pathway-header{
            font-size: 14px;
           }
}

@media screen and (max-width:600px) {
    .pathway-container{
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .pathway-left{
        width: 100%;
    }   
    .curator-details{
        width: 100%;
           }
    .pathway-line{
   gap: 10px;
}
    .pathway-rigth{
        flex-basis: 80%;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }

    .pathway-card{
        flex-direction: column;
        width: 350px;
    }
   
    .pathway-left img{
        width: 350px;
        height: 100%;
        object-fit: cover;
    }

    .card-btn{
        width: 50px;
        height: 50px;
    font-size: 14px;
    padding: 1rem;
    


    }

   .curator-details div{
width: 100%;
   }
   .pathway-detail {
    font-size: 11px;
   }
   .curator-details{
    font-size: 14px;
   }
   .curator-bg h6{
    font-size: 12px;
   }
   .pathway-header{
    font-size: 12px;
   }

}

@media screen and (max-width:400px) {
    .pathway-detail {
        font-size: 10px;
       }
       .curator-details{
        font-size: 12px;
       }
       .curator-bg h6{
        font-size: 10px;
       }
       .pathway-header{
        font-size: 10px;
       }
       .line1{
         left: 6.5%;
        }
        .pathway-line{
            gap: 5px;
         }
        .pathway-left img{
            width: 300px;
            height: 100%;
            object-fit: cover;
        }
        .pathway-card{
            width: 300px;
        }
        .pathway-card-right{

            width: 300px;
        }

}

@keyframes marquee {
    0% {
      left: 0;
    }
    100% {
      left: -100%;
    }
  }
  