.nav-2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    

background-color:  #EBF2FB;
padding: 0.5rem;
padding-left: 5rem;
padding-right: 5rem;

}
.nav-2 img{
    width:30px;
    height: 30px;
}
.nav-social-icons i{
    font-size: 25px;
    color: white;
    background-color: #062451;
    padding: 0.125rem;
    margin: 0.25rem;
}

@media screen and (max-width:450px) {
    .nav-2{
        padding-right: 2rem;
        padding-left: 2rem;
    }
}