.footer-container {
  width: 90%;
  margin: 0 auto;
  margin-top: 5rem;
  padding: 2rem;
  text-align: center;
}

.footer-main {
  margin: 0 auto;
}

.products h4 {
  color: #23a6f0;
  font-family: "Montserrat", sans-serif;
}
.products h1 {
  margin-top: 2rem;
  color: white;
  font-family: "Montserrat", sans-serif;
}

.products p {
  color: rgb(228, 226, 226);
  margin-top: 2rem;
  font-size: 14px;
  padding-left: 5rem;
  padding-right: 5rem;
  font-family: "Montserrat", sans-serif;
}

.newsletter {
  width: 380px;
  margin: 0 auto;
  margin-top: 2rem;
  background-color: aliceblue;
  position: relative;
  height: 35px;
  border-radius: 5px;
  display: flex;
}
.newsletter input {
  padding: 1rem;
  border-radius: 5px;
  width: 70%;
  border: none;
}
.newsletter input:focus {
  outline: none;
}

.subscribe {
  position: absolute;
  padding: 1rem;
  right: 0;
  border: none;
  background-color: #23a6f0;
  padding: 0.6rem;
  border-radius: 5px;
}

.footer {
  background: white;
  padding: 0.5rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2rem;
}
.social-media a{

}
.footer-logo{
  width: 40px;
  margin-top: 1rem;
}

.f-heading {
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 1.5rem;
}
.footer-e-btn {
  padding: 1rem;
  margin-top: 1rem;
  background-color: #23a6f0;
  border-radius: 10px;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.f-card {
  flex-basis: 320px;
  margin: 1rem;
}
.social-media  {
  color: #23a6f0;
  font-size: 30px;
  margin-top: 0.5rem;
}
.social-media  i {
  margin-right: 1.5rem;
  cursor: pointer;
}

.f-text p {
  font-size: 14px;
  margin-bottom: 1rem;
  color: gray;
  font-weight: 600;
}


@media screen and (max-width:600px) {
  
  .footer-logo{
    width: 20px;
  }
  .products p{
  padding-left: 1rem;
  padding-right: 1rem;
}
  .footer-container {
    width: 95%;
    padding: .5rem;
  }
}