.ty-container {
  width: 50%;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
padding-top: 2rem;



}
.ty-page{
  background: #062451;
  margin: 0;
}
.ty-social-media {
  color: white;
  font-size: 25px;
  margin-top: 0.5rem;
}
.back-btn{
  cursor: pointer;
}
.ty-container h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 50px;
  margin-top: 3rem;
}
.ty-container p {
  width: 70%;
  text-align: center;
  margin-top: 2rem;
}
.ty-container button {
  border-radius: 25px;
  margin-top: 2rem;
  padding: 0.8rem;
  background-color: #e6f6ff;
}

.ty-icons {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 1rem;
}
.ty-social-media {
  cursor: pointer;
}
.ty-social-media i {
  margin-right: 1rem;
}

@media screen and (max-width:600px) {

  .ty-container {
    width: 90%;


  }
  .ty-img{
    width: 250px;
  }
  
}