.courses-container {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 1rem;

  margin-top: 8rem;
}
.coming-soon {
  width: 80%;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;
}
.course-main-heading h3 {
  color: #23a6f0;
}
.course-main-heading h2 {
  margin-top: 2rem;
  font-size: 2rem;
  color: white;
}
.course-main-heading p {
  color: white;
  margin-top: 1rem;
}
.courses {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.course-card {
  margin-top: 2rem;
  border-radius: 5px;
  background: #ededed;

  width: 350px;
}
.course-card1 {
  margin-top: 2rem;
  border-radius: 5px;
  background: #ededed;
  filter: blur(0.3rem);
  width: 350px;
}

.course-img-container {
  position: relative;
}
.course-img {
  min-width: 350px;
}
.course-img-container span {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #e74040;
  border-radius: 5px;
  margin-top: 0.5rem;
  margin-left: 0.3rem;
  padding: 0.3rem;
  color: white;
  font-size: 14px;
}
.card-main {
  padding: 2rem;
}

.card-main1 {
  filter: blur(0.3rem);
  position: relative;
}
.card-coming-soon {
  position: absolute;

  background-color: red;
  z-index: 200;
  color: white;
  padding: 2rem;
  border-radius: 25px;
}
.main-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}
.main-title p {
  color: #23a6f0;
  font-weight: bold;
}
.main-title div {
  background-color: black;
  color: white;
  padding: 0.4rem;
  border-radius: 10px;
  font-size: 12px;
}
.star-icon {
  color: gold;
  margin-right: 0.3rem;
}

.main-course-title {
  margin-top: 1rem;
  font-weight: bold;
}
.main-desc {
  margin-top: 1rem;
  font-size: 12px;
  font-weight: 200;
  letter-spacing: 1px;
  color: gray;
}

.main-stud-cnt {
  margin-top: 1rem;
  color: gray;
}
.main-stud-cnt i {
  margin-right: 0.5rem;
  font-weight: 600;
}

.price {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
.price .p1 {
  color: rgb(156, 158, 156);
  font-weight: bold;
  margin-right: 1rem;
}
.price .p2 {
  color: #40bb15;
  font-weight: bold;
}
.main-footer {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.time,
.level {
  display: flex;
  align-items: center;

}


.time i {
  font-size: 20px;
  color: #23a6f0;
}
.time p,
.level p {
  margin-left: 0.5rem;
  font-size: 25px;
  color: gray;
  font-weight: 500;
}
.level i {
  font-size: 20px;
  background-color: ;
  color: #40bb15;
}
.learn-more {
  margin-top: 1.5rem;
  border: 1.5px solid #23a6f0;
  border-radius: 25px;
  background-color: white;
  padding: 0.7rem;
  color: #23a6f0;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
}
.learn-more i {
  margin-right: 0.5rem;
  font-size: 18px;
  font-weight: 600;
}
.see-more {
  color: white;
  font-weight: 500;
  text-decoration: underline;
  margin: 0 auto;
  text-align: center;

  margin-top: 2rem;
}
